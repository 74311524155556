<template>
  <div :class="rootClasses">
    <HeaderController />
    <FeedControllerSkeleton />
    <main class="footer-pages-layout__main">
      <div class="footer-pages-layout__main_gradient" />
      <div class="footer-pages-layout__main_page">
        <slot />
      </div>
    </main>
    <StatisticControllerSkeleton>
      <StatisticController
        class="techies-layout__statistic"
        :keys-show="[
          EStatisticItemVariants.OPENED,
          EStatisticItemVariants.USERS,
          EStatisticItemVariants.UPGRADES,
          EStatisticItemVariants.ONLINE,
        ]"
      />
    </StatisticControllerSkeleton>
    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
import { EStatisticItemVariants } from '~/config/apps/index.types';

const route = useRoute();

const rootClasses = computed(() => ({
  'footer-pages-layout': true,
  [`footer-pages-layout--${route.meta.layoutClass}`]: true,
}));
</script>

<style lang="scss" scoped src="assets/layouts/footer-pages.scss"></style>
